import React from "react";
import styled from "styled-components";
import Card from "../base/Card";
import Picture from "../base/Picture";
import Text from "../base/Text";

import Link from "../base/Link.jsx";
import MediaQuery from "react-responsive";

const IntroductionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
    }
    // @media (min-width: 767px){
    //     flex-direction:column;
    // }

    background-color: #efe2ba;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const NameContainer = styled.div`
    margin-left: 100px;
    @media (max-width: 767px) {
        margin-left: 40px;
    }
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Introduction = () => {
    return (
        <IntroductionContainer>
            <MediaQuery maxDeviceWidth={767}>
                {" "}
                {/* mobile */}
                <Card width="80%">
                    <Picture path="profile-cropped.jpg" />
                </Card>
            </MediaQuery>

            <MediaQuery minDeviceWidth={767}>
                <Card height="600px" width="600px">
                    <Picture path="profile-cropped.jpg" />
                </Card>
            </MediaQuery>
            <NameContainer>
                <Text type="title">Matthew Chiang</Text>
                <LinkContainer>
                    <Link path={"mailto:matthew.chiang.myc@gmail.com"}>
                        Email
                    </Link>
                    <Link
                        path={"https://www.linkedin.com/in/matthewchiangmyc/"}
                    >
                        Linkedin
                    </Link>
                    <Link path={"https://github.com/Matthew-Chiang"}>
                        Github
                    </Link>
                    <Link path={"https://devpost.com/Matthew-Chiang"}>
                        Devpost
                    </Link>
                </LinkContainer>
            </NameContainer>
        </IntroductionContainer>
    );
};

export default Introduction;
