import React, { useState } from 'react';
import styled from 'styled-components';

import Picture from '../base/Picture';
import OverlayedCard from '../base/OverlayedCard.jsx';
import { ButtonBase } from '@material-ui/core';

import Color from '../../constants/colors';
import HoverText from './HoverText';

const TrioIamgeContainer = styled.div`
    margin-top: 80px;
    margin-bottom: 80px;
    display:flex;
    // justify-content:center;
`;

const ImagesContainer = styled.div`
    display:flex;
`;


const TrioIamge = ({leftItem,rightItem,middleItem,startPosition,
    clickedCB, sideColor, middleColor}) => {

    const [hover,setHover] = useState('');

    const handleClick = (position) => {
        const thisPosition = position+startPosition
        clickedCB(thisPosition)

    }

    return(
        <TrioIamgeContainer>
            <div style={{marginRight:'30px'}}>
                <OverlayedCard 
                    height="350px" 
                    width="350px"
                    color={Color[sideColor]}
                >
                    <ButtonBase
                        focusRipple
                        onClick={()=>{handleClick(0)}}
                        onMouseEnter={()=>{setHover('left')}}
                        onMouseLeave={()=>{setHover('')}}
                    >   
                        {hover === 'left' &&
                            <HoverText title={leftItem.title} text={leftItem.hover} />
                        }
                        <Picture path={leftItem.imagePath} />
                    </ButtonBase>
                </OverlayedCard>
            </div>

            {middleItem !== null &&
                <div style={{marginLeft:'30px',marginRight:'30px'}}>
                    <OverlayedCard 
                        height="350px" 
                        width="350px"
                        color={Color[middleColor]}
                    >
                        <ButtonBase
                            focusRipple
                            onClick={()=>{handleClick(1)}}
                            onMouseEnter={()=>{setHover('middle')}}
                            onMouseLeave={()=>{setHover('')}}
                        >
                            {hover === 'middle' &&
                                <HoverText title={middleItem.title} text={middleItem.hover} />
                            }
                            <Picture path={middleItem.imagePath} />
                        </ButtonBase>
                    </OverlayedCard>
                </div>
            }

            {rightItem !== null &&
                <div style={{marginLeft:'30px'}}>
                    <OverlayedCard 
                        height="350px" 
                        width="350px"
                        color={Color[sideColor]}
                    >
                        <ButtonBase
                            focusRipple
                            onClick={()=>{handleClick(2)}}
                            onMouseEnter={()=>{setHover('right')}}
                            onMouseLeave={()=>{setHover('')}}
                        >
                            {hover === 'right' &&
                                <HoverText title={rightItem.title} text={rightItem.hover} />
                            }
                            <Picture path={rightItem.imagePath} />
                        </ButtonBase>
                    </OverlayedCard>
                </div>
            }
        </TrioIamgeContainer>
    );
};   

export default TrioIamge;