import React from 'react';
import styled from 'styled-components';

import OverlayedCard from './OverlayedCard';

const StyledImage = styled.img`
    max-width:100%;
    height:auto;
`;

const Picture = ({path}) => {
    return(
        <StyledImage src={require('../../assets/images/'+path)}/>
    );
}   

export default Picture;