import React from 'react';
import styled from 'styled-components';

import Picture from '../base/Picture.jsx';
import OverlayedCard from '../base/OverlayedCard.jsx';
import TextItem from '../compound/TextItem';
import Color from '../../constants/colors';

const ImageAndTextContainer = styled.div`
    display:flex;
    align-items:center;
`;

const Container = styled.div`
    margin-bottom: 30px;
    margin-top: 40px;
`;

const ImageContainer = styled.div`
    width:30%;
    padding:20px;
    background-color:white;
`;

const TextContainer = styled.div`
    width:70%;
    margin-left: 30px;
    margin-right:30px;
    text-align:left;
`;

const ImageAndText = ({item,color}) => {
    const {imagePath} = item;
    return(
        <Container>
            <OverlayedCard color={Color[color]} >
                <ImageAndTextContainer>
                    <ImageContainer>
                        <Picture path={imagePath} />
                    </ImageContainer>
                    <TextContainer>
                        <TextItem item={item}/>
                    </TextContainer>
                </ImageAndTextContainer>
            </OverlayedCard>
        </Container>
    );
};   

export default ImageAndText;