import React, { useState } from 'react';
import styled from 'styled-components';

import Picture from '../../base/Picture';
import OverlayedCard from '../../base/OverlayedCard.jsx';
import ButtonBase from '@material-ui/core/ButtonBase'

import Color from '../../../constants/colors';

const TrioIamgeContainer = styled.div`
    margin-top: 80px;
    margin-bottom: 80px;
    display:flex;
    // justify-content:center;
`;

const ImagesContainer = styled.div`
    display:flex;
`;


const MobileImage = ({item,startPosition,clickedCB, color}) => {

    const handleClick = (position) => {
        const thisPosition = position+startPosition
        clickedCB(thisPosition)
    }

    return(
        <TrioIamgeContainer>
            <div style={{marginRight:'30px'}}>
                <OverlayedCard 
                    height="350px" 
                    width="350px"
                    color={Color[color]}
                >
                    <ButtonBase
                        focusRipple
                        onClick={()=>{handleClick(0)}}
                    >   
                        <Picture path={item.imagePath} />
                    </ButtonBase>
                </OverlayedCard>
            </div>
        </TrioIamgeContainer>
    );
};   

export default MobileImage;