import React from 'react';
import styled from 'styled-components';

import TextItem from '../compound/TextItem';
import OverlayedCard from '../base/OverlayedCard.jsx';
import Arrow from '../base/Arrow';

import Color from '../../constants/colors';


const StyledPopup = styled.div`
    margin-top:-75px;
`;

const ArrowContainer = styled.div`
    display:flex;
    justify-content: ${({pos})=>handleArrowPosition(pos)}
`;

const TextContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
`;

const handleArrowPosition = (pos) => {
    switch(pos){
        case 'left':
            return 'flex-start;';
        case 'middle':
            return 'center;';
        case 'right':
            return 'flex-end;';
    }
}

const PopupProject = ({item,pos,color}) => {
    return(
        <StyledPopup>
            <ArrowContainer pos={pos} >
                <Arrow color={Color[color]}/>
            </ArrowContainer>
            <OverlayedCard color={Color[color]} width="100%" >
                <TextContainer>
                    {console.log(item)}
                    <TextItem item={item} />
                </TextContainer>
            </OverlayedCard>
        </StyledPopup>
    );
};   

export default PopupProject;