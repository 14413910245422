import React from 'react';
import styled from 'styled-components';

import Text from '../base/Text.jsx';



const StyledHover = styled.div`
    background-color:rgba(0,0,0,0.7);
    
    z-index:1;
    position:absolute;

    width:100%;
    height:100%;

    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`;

const ArrowContainer = styled.div`
    display:flex;
`;

const TextContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
`;


const HoverText = ({title,text}) => {
    return(
        <StyledHover>
            <Text type="hoverTitle">{title}</Text>
            <Text type="hoverText">{text}</Text>
        </StyledHover>
    );
};   

export default HoverText;