import React, {useState} from 'react';
import styled from 'styled-components';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Text from '../base/Text.jsx';
import Link from '../base/Link.jsx';
import { Button } from '@material-ui/core';

const TextContainer = styled.div`
    display:flex;
    flex-direction:column;
    text-align:left;
    width:100%;
    margin:10px;
    padding-right:40px;
`;

const Container = styled.div`
    width:100%;
    @media (max-width: 767px){
        padding:10px;
    }
    @media (min-width: 767px){
        padding:20px;
    }
`;

const MoreInfoContainer = styled.div`
    display:flex;
    align-items:center;
`;

const Paragraph = styled.div`
    margin-bottom:10px;

`;


const ImageAndText = ({item}) => {
    const {imagePath,title,subtitle,dates,description,hover,links,moreInfo} = item;
    const [moreClicked,setMoreClicked] = useState(false)

    return(
        <Container>
                <TextContainer>
                    <Text type="subtitle">{title}</Text>
                    <Text type="allCaps">{subtitle}</Text>
                    <Text type="light">{dates}</Text>
                    <Text>{description}</Text>
                    <div style={{marginBottom:'10px'}}>
                        {links.map((link)=>(
                            <Link path={link.path} key={link.path}>
                                {link.name}
                            </Link>
                        ))}
                    </div>
                    {moreInfo &&
                        <Button onClick={()=>{setMoreClicked(!moreClicked)}}>
                            {moreClicked ? 
                                <MoreInfoContainer>
                                    <ExpandLessIcon />
                                    <Text>Less Information</Text>
                                </MoreInfoContainer>
                                : 
                                <MoreInfoContainer>
                                    <ExpandMoreIcon/>
                                    <Text>More Information</Text>
                                </MoreInfoContainer>
                            }
                        </Button>
                    }
                    {moreClicked &&
                        <div>
                            {moreInfo.map((text,ind)=>(
                                <Paragraph key={ind}>
                                    <Text>
                                        {text}
                                    </Text>
                                </Paragraph>
                            ))}
                        </div>
                    }

                </TextContainer>
        </Container>
    );
};   

export default ImageAndText;