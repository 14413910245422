import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const StyledText = styled(Typography)`
    color:#333333;
    margin:5px;    
    ${({type})=>handleTextType(type)}
`;

//types: regular title subtitle light

const handleTextType = (type) =>{
    switch(type){
        case "title":
            return "font-family:'Mukta Vaani',sans-serif;font-weight:800;font-size:60px;";
        case "subtitle":
            return"font-family:Mukta Vaani,sans-serif;font-weight:700;font-size:30px;";
        case "light":
            return"font-family:'Assistant', sans-serif;font-weight:200;font-size:20px;";
        case "link":
            return "font-family:Assistant, sans-serif;font-weight:800;font-size:22px;color:#337ab7";
        case "allCaps":
            return "font-family:Assistant, sans-serif;font-weight:800;font-size:24px;font-variant:small-caps";
        case "hoverTitle":
            return "font-family:Assistant, sans-serif;font-weight:800;font-size:40px;font-variant:small-caps;color:white;";
        case "hoverText":
            return "font-family:Assistant, sans-serif;font-weight:800;font-size:20px;color:white;";
        default:
            return"font-family:Assistant, sans-serif;font-weight:400;font-size:20px;";
    }
}

const Text = ({children,type}) => {
    return(
        <StyledText type={type}>
            {children}
        </StyledText>
    );
}   

export default Text;