import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
    margin-bottom: -2px;
`;


const PopupProject = ({color}) => {
    return(
        <StyledSVG width="350" height="80">
            <polygon points="113 80 175 10 237 80" fill="white"/>
            <polyline points="115 70 175 10 235 70" stroke={color} strokeWidth="16"
      strokeLinecap="round" fill="none" strokeLinejoin="miter"/>

  
        </StyledSVG>
    );
};   

export default PopupProject;