import React, { useState } from 'react';
import styled from 'styled-components';

import Text from '../base/Text.jsx';
import TrioImage from '../compound/TrioImage';
import ResumeItem from '../Objects/ResumeItem';
import PopupProject from '../compound/PopupProject';

import ProjectsText from '../../assets/writing/Projects'
import colorOrder from '../../constants/colorOrder';

const ProjectsContainer = styled.div`
    
`;

const Projects = () => {
    const [clicked,setClicked] = useState(-1)

    const handleClick = (position) =>{
        if (clicked === position){
            setClicked(-1)
        }
        else{
            setClicked(position)
        }
    }

    return(
        <ProjectsContainer>
            <Text type="title">Projects</Text>
            {ProjectsText.filter((element,ind)=>{
                return ind % 3 === 0;
            }).map((proj,ind)=>{
                const leftItem = new ResumeItem(
                    proj.image,
                    proj.title,
                    proj.subtitle,
                    proj.date,
                    proj.description,
                    proj.hover,
                    proj.links,
                    proj.text
                )
                const leftInd = ind*3;
                const middleInd = ind*3 + 1;
                const rightInd = ind*3+2;

                const middleItem = (middleInd >= ProjectsText.length) 
                    ? null
                    : new ResumeItem(
                        ProjectsText[middleInd].image,
                        ProjectsText[middleInd].title,
                        ProjectsText[middleInd].subtitle,
                        ProjectsText[middleInd].date,
                        ProjectsText[middleInd].description,
                        ProjectsText[middleInd].hover,
                        ProjectsText[middleInd].links,
                        ProjectsText[middleInd].text
                    );

                const rightItem = (rightInd >= ProjectsText.length) 
                ? null
                : new ResumeItem(
                    ProjectsText[rightInd].image,
                    ProjectsText[rightInd].title,
                    ProjectsText[rightInd].subtitle,
                    ProjectsText[rightInd].date,
                    ProjectsText[rightInd].description,
                    ProjectsText[rightInd].hover,
                    ProjectsText[rightInd].links,
                    ProjectsText[rightInd].text
                );

                const leftColor = colorOrder[(ind*2) % colorOrder.length]
                const middleColor = colorOrder[(ind*2+1)% colorOrder.length]
                
                return(
                    <div key={leftItem.title}>
                        <TrioImage 
                            leftItem={leftItem} 
                            middleItem={middleItem}
                            rightItem={rightItem}
                            startPosition={leftInd}
                            clickedCB={handleClick}
                            sideColor={leftColor}
                            middleColor={middleColor}
                        />

                        {clicked === leftInd  &&
                            <PopupProject item={leftItem} pos='left' color={leftColor}/>
                        }

                        {clicked === middleInd  &&
                            <PopupProject item={middleItem} pos='middle' color={middleColor}/>
                        }

                        {clicked === rightInd  &&
                            <PopupProject item={rightItem} pos='right' color={leftColor}/>
                        }
                    </div>
                )
            })

            }
        </ProjectsContainer>
    );
};   

export default Projects;