import React from 'react';
import styled from 'styled-components';

import Picture from '../../base/Picture.jsx';
import OverlayedCard from '../../base/OverlayedCard.jsx';
import TextItem from '../../compound/TextItem';
import Color from '../../../constants/colors';

const ImageAndTextContainer = styled.div`
    // display:flex;
    // flex-direction:column;
    // align-items:flex-start;
`;

const Container = styled.div`
    margin-left:15px;
    margin-bottom: 30px;
    margin-top: 40px;
`;

const ImageContainer = styled.div`
    padding:20px;
    background-color:white;
`;

const TextContainer = styled.div`
    margin-left: 10px;
    margin-right:20px;
    text-align:left;
`;

const ImageAndText = ({item,color}) => {
    const {imagePath} = item;
    return(
        <Container>
            <OverlayedCard color={Color[color]} >
                <ImageAndTextContainer>
                    <ImageContainer>
                        <Picture path={imagePath} />
                    </ImageContainer>
                    <TextContainer>
                        <TextItem item={item}/>
                    </TextContainer>
                </ImageAndTextContainer>
            </OverlayedCard>
        </Container>
    );
};   

export default ImageAndText;