import React from 'react';
import './App.css';

import styled, { createGlobalStyle } from 'styled-components';

import Introduction from './components/sections/Introduction';
import AboutMe from './components/sections/AboutMe';
import Projects from './components/sections/Projects';
import Experience from './components/sections/Experience';
import Education from './components/sections/Education';
import MobileAboutMe from './components/mobile/sections/MobileAboutMe';
import MobileProjects from './components/mobile/sections/MobileProjects';

import { StylesProvider } from '@material-ui/core';
import MediaQuery from 'react-responsive';

const AppContainer = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 767px) {
        width: 767;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
`

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Assistant:200,400,800|Mukta+Vaani:700,800&display=swap');

    body {
        font-family: 'Assistant', sans-serif;
    }
`

function App() {
    return (
        <div>
            <StylesProvider injectFirst>
             <Introduction />
            <div className="App">
                <AppContainer>
                    <MediaQuery maxDeviceWidth={767}> {/* mobile */}
                        <MobileAboutMe />
                        <MobileProjects />
                    </MediaQuery>
                    <MediaQuery minDeviceWidth={767}>
                        <AboutMe />
                        <Projects/>
                    </MediaQuery>
                    <Experience color={2}/>
                    <Education color={5} />
                </AppContainer>
            </div>
            </StylesProvider>

            <GlobalStyles />
        </div>
    );
}

export default App;
