import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
    font-family:Assistant, sans-serif;
    font-weight:800;
    font-size:22px;
    color:#337ab7;
    text-decoration:none;
    margin:5px 20px 5px 5px;
`;

const Link = ({children,path}) => {
    return(
        <StyledLink target="_blank" href={path}>
            {children}
        </StyledLink>
    );
};   

export default Link;