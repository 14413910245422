export default class ResumeItem{
    constructor(imagePath,title,subtitle,dates,description,hover,links,moreInfo){
        this.imagePath = imagePath;
        this.title = title;
        this.subtitle = subtitle;
        this.dates = dates;
        this.description = description;
        this.hover = hover;
        this.links = links;
        this.moreInfo = moreInfo;
    }
}