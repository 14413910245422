import React from 'react';
import styled from 'styled-components';

import MediaQuery from 'react-responsive';

import EducationText from '../../assets/writing/Education.json';
import Text from '../base/Text.jsx';
import ImageAndText from '../compound/ImageAndText.jsx';
import MobileImageAndText from '../mobile/compound/MobileImageandText';

import ResumeItem from '../Objects/ResumeItem'
import colorOrder from '../../constants/colorOrder';


const EducationContainer = styled.div`
    margin-top: 50px; 
`;

const Education = ({color}) => {
    return(
        <EducationContainer>
            <Text type="title">Education</Text>
            {EducationText.map((edu,ind)=>{
                const resumeItem = new ResumeItem(
                    edu.image,
                    edu.title,
                    edu.subtitle,
                    edu.date,
                    edu.description,
                    "",
                    edu.links,
                    edu.text
                )
                return(
                    <div>
                        <MediaQuery maxDeviceWidth={767}> {/* mobile */}
                            <MobileImageAndText
                                key={edu.title}
                                item={resumeItem}
                                color={colorOrder[color]}
                            />
                        </MediaQuery>
                        <MediaQuery minDeviceWidth={767}>
                            <ImageAndText 
                                key={edu.title}
                                item={resumeItem}
                                color={colorOrder[color]}
                            />
                        </MediaQuery>
                    </div>
                )
            })}
        </EducationContainer>
    );
};   

export default Education;