import React from 'react';
import styled from 'styled-components';

import MediaQuery from 'react-responsive';

import ExperienceText from '../../assets/writing/Experience.json';
import Text from '../base/Text.jsx';
import ImageAndText from '../compound/ImageAndText.jsx';
import ResumeItem from '../Objects/ResumeItem'
import colorOrder from '../../constants/colorOrder';
import MobileImageAndText from '../mobile/compound/MobileImageandText';

const ExperienceContainer = styled.div`
    
`;

const Experience = ({color}) => {
    return(
        <ExperienceContainer>
            <Text type="title">Experience</Text>
            {ExperienceText.map((exp,ind)=>{
                const resumeItem = new ResumeItem(
                    exp.image,
                    exp.title,
                    exp.subtitle,
                    exp.date,
                    exp.description,
                    "",
                    exp.links,
                    exp.text
                )
                return(
                    <div>
                        <MediaQuery maxDeviceWidth={767}> {/* mobile */}
                            <MobileImageAndText
                                key={exp.title}
                                item={resumeItem}
                                color={colorOrder[color]}
                            />
                        </MediaQuery>
                        <MediaQuery minDeviceWidth={767}>
                            <ImageAndText 
                                key={exp.title}
                                item={resumeItem}
                                color={colorOrder[color]}
                            />
                        </MediaQuery>
                    </div>
                )
            })}
        </ExperienceContainer>
    );
};   

export default Experience;