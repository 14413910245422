import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
    box-shadow: -18px -18px ${({color})=>color},
                rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, 
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    height: ${props=>props.height ? props.height : 'auto'};
    width: ${props=>props.width ? props.width : 'auto'};
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color:white;
    box-sizing: border-box;
`;

const OverlayedCard = ({children,height,width,color}) => {
    return(
        <StyledCard height={height} width={width} color={color}>
            {children}
        </StyledCard>
    );
};   

export default OverlayedCard;