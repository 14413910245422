import React, { useState } from 'react';
import styled from 'styled-components';

import Text from '../../base/Text';
import MobileImage from '../compound/MobileImage';
import ResumeItem from '../../Objects/ResumeItem';
import PopupProject from '../../compound/PopupProject';
import ProjectsText from '../../../assets/writing/Projects'
import colorOrder from '../../../constants/colorOrder';


const ProjectsContainer = styled.div`
    margin-left:15px;
`;

const Projects = () => {
    const [clicked,setClicked] = useState(-1)

    const handleClick = (position) =>{
        if (clicked === position){
            setClicked(-1)
        }
        else{
            setClicked(position)
        }
    }

    return(
        <ProjectsContainer>
            <Text type="title">Projects</Text>
            {ProjectsText.map((proj,ind)=>{
                const Item = new ResumeItem(
                    proj.image,
                    proj.title,
                    proj.subtitle,
                    proj.date,
                    proj.description,
                    proj.hover,
                    proj.links,
                    proj.text
                )

                const color = colorOrder[ind % colorOrder.length]
                
                return(
                    <div key={Item.title}>
                        <MobileImage 
                            item={Item} 
                            startPosition={ind}
                            clickedCB={handleClick}
                            color={color}
                        />

                        {clicked === ind  &&
                            <PopupProject item={Item} pos='left' color={color}/>
                        }
                        
                    </div>
                )
            })

            }
        </ProjectsContainer>
    );
};   

export default Projects;