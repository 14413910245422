import React from 'react';
import styled from 'styled-components';
import Picture from '../../base/Picture';
import Text from '../../base/Text';

import AboutMeText from '../../../assets/writing/AboutMe.json'

const AboutMeContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding-top:25px;
`;

const OctoContainer = styled.div`
    width:100%;    
`;

const TextContainer = styled.div`
    margin-left:30px;
    text-align:justify;
`;


const AboutMe = () => {
    return(
        <AboutMeContainer>
            <TextContainer>
                <Text type="title">About Me</Text>
                {AboutMeText.text.map((text,ind)=>(
                    <div style={{paddingBottom:'10px'}}>
                        <Text key={ind}>{text}</Text>
                    </div>
                ))}
            </TextContainer>
            <OctoContainer>
                <Picture path='octo.gif' />
            </OctoContainer>
        </AboutMeContainer>
    );
};   

export default AboutMe;